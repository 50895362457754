<template>
  <div class="ip-text-area">
    <label
      v-if="withLabel"
      :for="id"
    >
      <span>{{ label }}</span>
      <span
        v-if="showRequiredIcon"
        class="required-icon">
          *
      </span>
    </label>
    <textarea
      :id="id"
      :name="name"
      :value="value"
      @input="$emit('input', $event.target.value)"
      :placeholder="placeholder"
      :invalid="invalid"
      :disabled="disabled"
    >
    </textarea>
  </div>
</template>

<script>
export default {
  props: {
    /**
     * @property {string} id
     */
    id: {
      type: String,
      required: true
    },
    /**
     * @property {boolean} withLabel
     */
    withLabel: {
      type: Boolean,
      default: true
    },
    /**
     * @property {string} name
     */
    name: {
      type: String,
      required: true
    },
    /**
     * @property {string|number} value
     */
    value: {
      type: String,
      default: ''
    },
    /**
     * @property {string} label
     */
    label: {
      type: String,
      default: ''
    },
    /**
     * @property {string} placeholder
     */
    placeholder: {
      type: String,
      default: 'placeholder'
    },
    /**
     * @property {boolean} invalid
     */
    invalid: {
      type: Boolean,
      default: false
    },
    /**
     * @property {boolean} disabled
     */
    disabled: {
      type: Boolean,
      default: false
    },
    /**
     * @property {boolean} showRequiredIcon
     */
    showRequiredIcon: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped lang="scss">
.ip-text-area{

  label {
    font-weight: 500;
    font-size: 14px;
    line-height: 16.71px;
    color: $black;
    margin-bottom: 3px;
    display: flex;
  }

  textarea {
    display: block;
    outline: none !important;
    background: $white;
    border: 1px solid $lightGray;
    box-sizing: border-box;
    padding: 6px 12px;
    color: $darkGray;
    min-height: 115px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;

    &::placeholder {
      color: $midGray;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
    }

    &:focus {
      border-color: $blue;
    }

    &[disabled] {
      color: $midGray;
      background: $gray;
      border-color: $lightGray;
    }

    &[invalid] {
      border-color: $red;
    }
  }
}
</style>
