<template>
  <div class="loader-contaier" v-if="isLoading">
    <ip-loader />
  </div>
  <div class="create-task" v-else>
    <div class="create-task__head">
      <div class="title">{{ taskTheme }}</div>
      <div class="desc"> {{ task.phone }} ({{ getUserName(task.user) }})</div>
    </div>
    <div class="create-task__body">
      <div class="title">
        Описание
      </div>
      <div class="create-task__elem">
        <ip-text-area
          name="problem"
          id="problem"
          v-model="problem"
          label="Описание проблемы"
          placeholder="Текст"
          v-validate="{required: true}"
          :invalid="errors.has('problem')" />
      </div>
      <div class="create-task__elem">
        <ip-text-area
          name="solution"
          id="solution"
          v-model="solution"
          label="Предложенное решение"
          placeholder="Текст"
          v-validate="{required: true}"
          :invalid="errors.has('solution')" />
      </div>
      <ip-button theme="rounded" color="blue" @click="createTask">
        Сохранить
      </ip-button>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import IpTextArea from "../components/IpTextArea";
import IpButton from "../components/IpButton";
import IpLoader from "../components/IpLoader";
import apiRoutes from "../consts/apiRoutes";
import CommonMixin from "../mixins/Common.mixin";

const themes = {
  else: 'Другое',
  consultation: 'Консультация',
  law_and_security: 'Юридические или вопросы безопасности'
};

export default {
  name: 'task-create',

  mixins: [CommonMixin],

  components: {
    IpTextArea,
    IpButton,
    IpLoader
  },

  data() {
    return {
      problem: '',
      solution: '',
      isLoading: false
    };
  },

  mounted() {
    if (!this.task) {
      this.$router.push({ name: 'tasks' });
    }
  },
  
  beforeDestroy() {
    this.setTask(null);
  },

  computed: {
    ...mapState('task', {
      task: state => state.task
    }),

    taskTheme() {
      return themes[this.task.theme];
    }
  },

  methods: {
    ...mapMutations('task', [
      'setTask'
    ]),

    async createTask() {
      const isValid = await this.$validator.validate();

      if (!isValid) {
        return;
      }

      this.isLoading = true;

      const data = {
        phone: this.task.phone,
        type: this.task.theme,
        problem: this.problem,
        solution: this.solution
      };

      try {
        await this.axios.post(apiRoutes.createTask, data);
        this.isLoading = false;
        this.$router.push({ name: 'tasks' });
      } catch(error) {
        console.warn(error);
        this.isLoading = false;
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .create-task{
    height: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    &__head{
      margin-bottom: 10px;
      flex: none;
      border-radius: 4px;
      padding: 16px 24px;
      background: $white;
      box-shadow: 0px 25px 50px -20px rgba($black, 0.1);
      .title{
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 29px;
        color: $black;
        margin-bottom: 8px;
      }
      .desc{
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        color: $black;
      }
    }
    &__body{
      flex: 1 1 auto;
      margin-bottom: 10px;
      border-radius: 4px;
      padding: 16px 24px;
      background: $white;
      box-shadow: 0px 25px 50px -20px rgba($black, 0.1);
      .title{
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 29px;
        color: $black;
        margin-bottom: 16px;
      }
    }
    &__elem{
      margin-bottom: 14px;
      ::v-deep textarea{
        width: 100%;
      }
    }
  }
</style>